// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layouts/auth.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layouts/auth.tsx");
  import.meta.hot.lastModified = "1735214425757.634";
}
// REMIX HMR END

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "@remix-run/react";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useOptionalUser } from "~/utils";
import { getMediaPath } from "~/utils/helper";
export default function AuthLayout({
  children
}) {
  _s();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useOptionalUser();
  const goBack = () => {
    navigate("/");
  };
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);
  return <div className="d-flex flex-column flex-root" id="kt_app_root" style={{
    backgroundImage: `url(assets/media/auth/bg9-dark.jpg)`
  }}>
      {/* <style>body { background-image: url('assets/media/auth/bg4.jpg'); } [data-bs-theme="dark"] body { background-image: url('assets/media/auth/bg4-dark.jpg'); }</style> */}

      <button type="button" onClick={goBack} className="btn btn-light btn-active-light-primary" style={{
      position: "absolute",
      top: "20px",
      left: "20px"
    }}>
        <FontAwesomeIcon icon={faArrowLeft} /> Back
      </button>
      <div className="d-flex flex-column flex-column-fluid flex-lg-row">
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          <div className="d-flex flex-center flex-lg-start flex-column">
            <div className="mb-7">
              {/* <h1 className="text-white">AllinOne</h1> */}
              {/* <img alt="Logo" src="assets/media/logos/custom-3.svg" /> */}
              <img alt="AllinOne" src={getMediaPath("/logos/logo-gold.svg")} className="h-60px theme-light-show" onClick={() => navigate("/")} style={{
              cursor: "pointer"
            }} />
            </div>

            <h2 className="text-white fw-normal m-0">
              Branding tools designed for your business
            </h2>
          </div>
        </div>

        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-10 p-sm-20">
          <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-10 p-sm-20 w-100">
            <div className="d-flex flex-center flex-column flex-column-fluid p-sm-2 px-lg-10">
              {children}
            </div>
          </div>
        </div>
        <div id="toaster">
          <Toaster toastOptions={{
          position: "top-right",
          success: {
            style: {
              background: "green",
              color: "white"
            }
          },
          error: {
            style: {
              background: "red",
              color: "white"
            }
          }
        }} />
        </div>
      </div>
    </div>;
}
_s(AuthLayout, "DYZx0oj0881VUTHEkUjtm9xzGtA=", false, function () {
  return [useNavigate, useLocation, useOptionalUser];
});
_c = AuthLayout;
var _c;
$RefreshReg$(_c, "AuthLayout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;